<template>
  <div class="base-card">
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
let QRCode = require('qrcode')
export default {
  name: 'qrcode',
  props: {
    qrUrl: { type: String, default: ''},
    qrWidth: { type: String, default: ''}
  },
  mounted () {
    let canvas = document.getElementById('canvas')
    QRCode.toCanvas(canvas, this.qrUrl, function (error) {
      if (error) console.error(error)
    })
    this.$nextTick(() => {
      if (this.qrWidth){
        canvas.style.width = this.qrWidth
        canvas.style.height = this.qrWidth
      }
    })
  }
}
</script>

<style lang="less" scoped>
</style>