<template>
  <div class="item-form" v-if="appraisalItem && appraisalItem.type === 'score' && ruleMap && appraisalItem">
    <div class="title">{{appraisalItem.title}}：
      <fm-input-new :placeholder="ruleMap.minValue + '-' + ruleMap.maxValue" @blur="valueChange" v-model="data" v-if="ruleMap.type === 'input'"></fm-input-new>
      <fm-select absolute filterable :clearable="true" @change="valueChange" v-model="data" v-if="ruleMap.type === 'select'">
        <fm-option v-for="item in ruleMap.optionalList" :key="item.value" :value="item.value" :label="item.label"></fm-option>
      </fm-select>
      <span class="his-value" v-if="hisValue !== null">{{ruleMap.type === 'select' ? (ruleMap.optionalList.find(v => v.value === hisValue) || {label: hisValue}).label : hisValue}}</span>
    </div>
    <div class="input-remark" style="margin-top: 20px;" v-if="needRemark">
      备注：
      <fm-input-new v-model="remark"
        @blur="valueChange"
        type="textarea"
        placeholder="备注"
      ></fm-input-new>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemForm',
  props: {
    appraisalItem: { type: Object, default: () => null },
    hisData: { type: Object, default: null},
    needRemark: { type: Boolean , default: true},
    hisValue: {
      type: Number, default: null
    }
  },
  data () {
    return {
      data: null,
      remark: null
    }
  },
  watch: {
    hisData: {
      deep: true,
      handler () {
        this.data = this.hisData.value
        this.remark = this.hisData.remark
      }
    }
  },
  computed: {
    ruleMap () {
      let data = this.appraisalItem && this.appraisalItem.rule ? JSON.parse(this.appraisalItem.rule) : null
      if (data !== null) {
        data = Object.assign({}, {
          optionalList: [],
          minValue: null,
          maxValue: null,
          decimalNum: null,
          type: null
        }, data)
      }
      return data
    }
  },
  methods: {
    valueChange () {
      console.log('valueChange')
      this.$emit('valueChange')
    },
    setValue ({value, remark}) {
      this.data = value
      this.remark = remark
    },
    getValue () {
      if (this.ruleMap.type === 'input') {
        if (isNaN(this.data)) {
          this.$notice.info('请输入合理分值')
          throw new Error('valueError')
        }
        if (this.data === null || this.data === '') {
          this.$notice.info('请输入分值')
          throw new Error('valueError')
        }
        let value = Number(this.data)
        if (this.ruleMap.maxValue !== null && value > Number(this.ruleMap.maxValue)) {
          this.$notice.info('分值不能大于' + this.ruleMap.maxValue)
          throw new Error('valueError')
        }
        if (this.ruleMap.minValue !== null && value < Number(this.ruleMap.minValue)) {
          this.$notice.info('分值不能小于' + this.ruleMap.minValue)
          throw new Error('valueError')
        }
        return {
          value,
          remark: this.remark
        }
      } else if (this.ruleMap.type === 'select') {
        let data = this.ruleMap.optionalList.find(v => v.value === this.data)
        if (typeof data === 'undefined' || data === null) {
          this.$notice.info('请选择分值')
          throw new Error('valueError')
        }
        return {
          value: data.value,
          remark: this.remark
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.his-value {
  background: #FFCED1;
  padding: 5px 10px;
  border-radius: 3px;
  margin-left: 20px;
  color: #fff;

}
</style>