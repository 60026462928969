<template>
  <div class="doing-detail" v-loadingx="loading">
    <div class="top-c">
      <div class="info-1">
        考核管理 / 主持
      </div>
      <div class="info-2">
        考核准备: {{appraisalData ? appraisalData.title : ''}} {{appraisalData ? appraisalData.statusText : ''}}
      </div>
      <div class="info-1">{{appraisalData ? appraisalData.remark : ''}}</div>
      <div class="qr-img">
        <qrcode qrWidth="110px" :qrUrl="qrUrl" v-if="qrUrl"></qrcode>
        <span style="color:#333145;font-size: 16px;">扫码进入评选会场</span>
      </div>
    </div>
    <div class="bottom-c">
      <div class="b-l c-item">
        <div class="base-info">
          <div class="base-info-l">
            <img :src="checkImg" alt="">
            {{khstatus === 'doing' ? '考核进行中' : (khstatus === 'waitDoing' ? '考核未开始' : '考核已完成')}}
          </div>
          <div class="base-info-r" v-if="khstatus !== 'over' && appraisalData && appraisalData.type === 'scoring'">
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="btnAction(null, {key: 'over'})">{{khstatus === 'waitDoing' ? '开始' : (bkhList['ready'].length === 0 ? '完成' : '下一个')}}</a>
          </div>
        </div>
        <template v-if="khstatus === 'doing' && appraisalData && appraisalData.type === 'scoring'">
          <div class="doing-title">当前被考核人
            <div class="d-t-dian"></div>
          </div>
          <div href="javascript:;" style="position: relative;" class="bkhr-item" v-for="item in khzList" :key="item.id">
            <div>被考核人: {{item.userName}}</div>
            <div>代表单位: {{item.represent}}</div>
            <div>备注: {{item.remark}}</div>
            <div class="d-x"></div>
          </div>
          <div class="bkhr-item" style="position: relative;" v-if="khzList.length === 0">
            <div>当前无被考核人</div>
            <div class="d-x"></div>
          </div>
          <div class="doing-title">下一个被考核人
            <div class="d-t-dian" style="background: #2F5BEA;"></div>
          </div>
          <div href="javascript:;" class="bkhr-item" v-for="item in bkhList['ready']" :key="item.id">
            <div>被考核人: {{item.userName}}</div>
            <div>代表单位: {{item.represent}}</div>
            <div>备注: {{item.remark}}</div>
          </div>
          <div class="doing-note" v-if="bkhList['ready'].length === 0 && appraisalData && appraisalData.type === 'scoring'">
            无下一个被考核人
          </div>
        </template>
      </div>
      <div class="b-r c-item">
        <div class="base-info">
          <div class="base-info-l">
            <img :src="becheckImg" alt="">
            被考核人
          </div>
          <div class="base-info-r">
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickTitleMenuAll('tj')">统计结果</a><span>|</span>
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickTitleMenuAll('dfmx')">打分明细</a><span>|</span>
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickTitleMenuAll('px')">排序</a>
          </div>
        </div>
        <fm-table-new
          ref="main"
          :columns="baseColumns"
          export-file-name="被考核人"
          :data-list="showBkhList"
          border="row"
          :stripe="false">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </div>
    <fm-modal
      :value="openDialogMx"
      width="80%"
      :mask-closable="false"
      v-if="openDialogMx"
      theme="mh-withe"
      @cancel="openDialogMx = false">
      <fm-title
        :title-text="(chooseDataDetail ? chooseDataDetail.userName : '') + '得分明细'">
      </fm-title>
      <fm-table-new
        ref="details"
        export-file-name="得分明细"
        :columns="mxColumns"
        :data-list="mxList"
        :border="true"
        emptyPlaceholder="未打分"
        @cell-click="clickDf"
        :stripe="false">
      </fm-table-new>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="分组明细"
      :open-dialog.sync="openDialogDetail"
      :form-parms="formParmsDetail"
      label-alone
      label-align="left"
      :old-data="chooseDataDetail"
      form-width="800px"
      @formSubmit="formSubmitDetail"
      @handleClose="openDialogDetail = false">
    </fm-form-dialog>
    <fm-form-dialog
      :mask-closable="false"
      form-title="状态修改"
      :open-dialog.sync="openDialogStatus"
      :form-parms="formParmsStatus"
      label-alone
      label-align="left"
      :old-data="chooseDataDetail"
      form-width="800px"
      @formSubmit="formSubmitStatus"
      @handleClose="openDialogStatus = false">
    </fm-form-dialog>
    <fm-modal
      :value="openDialogResult"
      width="600px"
      :mask-closable="false"
      v-if="openDialogResult"
      theme="mh-withe"
      title="打分"
      @cancel="openDialogResult = false">
      <div v-for="(item, index) in itemList" :key="index">
        <ItemForm style="margin-bottom: 20px;" :ref="'appraisalItem_' + item.id" :appraisal-item="item" :his-value="(resultData.checkedData.find(v => v.appraisalItemId === item.id) || {value: null}).value"></ItemForm>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px;">
        <fm-btn @click="formSubmitresult" style="margin-right:20px">确定</fm-btn>
        <fm-btn @click="openDialogResult = false">取消</fm-btn>
      </div>
    </fm-modal>
    <fm-modal
      :value="openDialogResultVote"
      width="600px"
      :mask-closable="false"
      v-if="openDialogResultVote"
      theme="mh-withe"
      title="投票"
      @cancel="openDialogResultVote = false">
      <VoteForm @cancel="openDialogResultVote = false" @voteUpdate="loadMxList" v-if="openDialogResultVote" :appraisal-id="appraisalId" :appraisalGroupDetailId="resultData.appraisalGroupDetailId"></VoteForm>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="下一个"
      submit-btn-label="确定"
      :open-dialog.sync="openDialogNext"
      ref="nextForm"
      :form-parms="formParmsNext"
      label-alone
      label-align="left"
      :old-data="nextParm"
      form-width="800px"
      @formSubmit="formSubmitNext"
      @handleClose="openDialogNext = false">
    </fm-form-dialog>
    <be-check-sort @srotChange="loadDetailList" ref="bcsrot" :appraisalId="appraisalId"></be-check-sort>
  </div>
</template>

<script>
import store from '@/store'

import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalResultRequest,
  appraisalItemRequest
} from '../../api'

import TableActions from '@/components/base/TableActions'
import Qrcode from '@/components/base/qrcode'
import BeCheckSort from './cmp/sort'
import ItemForm from './cmp/ItemForm'
import VoteForm from './cmp/vote'

export default {
  components: {
    TableActions,
    Qrcode,
    BeCheckSort,
    ItemForm,
    VoteForm
  },
  data () {
    let statusMap = {}
    store.getters.appraisalGroupDetailStatusList.forEach((v) => {
      statusMap[v.key] = []
    })
    return {
      checkImg: 'static/images/check/check.png',
      becheckImg: 'static/images/check/becheck.png',
      checkList: [],
      loading: false,
      resultList: [],
      resultData: {},
      itemList: [],
      openDialogResult: false,
      openDialogResultVote: false,
      qrUrl: '',
      openDialogMx: false,
      openDialogNext: false,
      nextParm: null,
      appraisalId: null,
      mxList: [],
      bkhList: statusMap,
      appraisalData: null,
      chooseData: null,
      chooseDataDetail: null,
      openDialogDetail: false,
      openDialogStatus: false
    }
  },
  computed: {
    tableActions () {
      return this.appraisalData && this.appraisalData.isInc === 'yes' ? [{key: 'edit', label: '调整'}, {key: 'status', label: '状态修改'}] : [{key: 'edit', label: '调整'}, {key: 'status', label: '状态修改'}, {key: 'dfmx', label: '明细'}]
    },
    khstatus () {
      let status = 'doing'
      if (this.bkhList['ready'].length === 0 && this.bkhList['doing'].length === 0 && this.bkhList['over'].length === 0) {
        status = 'waitDoing'
      }
      if (this.bkhList['doing'].length === 0 && this.bkhList['ready'].length === 0 && this.bkhList['wait'].length === 0) {
        status = 'over'
      }
      return status
    },
    khzList () {
      return this.bkhList['doing']
    },
    showBkhList () {
      let data = []
      let keys = ['doing', 'ready', 'wait', 'over', 'giveup']
      keys.forEach(v => data = data.concat(this.bkhList[v]))
      return data.map(v => {
        let result = this.resultList.find(v1 => v1.beAppraisalGroupDetailId === v.id)
        return Object.assign({}, v, {
          value: result ? result.value : null,
          ranking: result ? result.ranking : null,
          gotta: result ? result.gotta : null,
        })
      })
    },
    mxColumns () {
      let data = [{field: 'beUserName', title: '', width: 100}].concat(this.checkList.map((v) => {
        return {
          field: v.id,
          title: this.appraisalData.isInc === 'yes' ? '**' : v.userName,
          width: 80
        }
      }))
      return data
    },
    baseColumns () {
      let data = [{
        field: 'appraisalGroupName',
        title: '分组'
      },
      {
        field: 'userName',
        title: '被考核人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'orderNum',
        title: '顺序'
      },
      {
        field: 'statusText',
        title: '状态'
      },
      {
        field: 'value',
        title: '分值'
      },
      {
        field: 'ranking',
        title: '名次'
      },
      {
        field: 'gotta',
        title: '得奖'
      },
      {
        title: '操作',
        slot: 'actions',
        width: 80,
        fixed: true,
        export: false,
        configurable: false
      }]
      return data
    },
    formParmsNext: {
      get () {
        let data = [{
          type: 'select',
          label: '下一个被考核人',
          key: 'doing',
          selectDatas: this.showBkhList.map((v) => {
            return {
              key: v.id,
              label: v.userName,
              data: v
            }
          })
        },
        {
          type: 'select',
          label: '下一个准备人',
          key: 'ready',
          selectDatas: this.showBkhList.map((v) => {
            return {
              key: v.id,
              label: v.userName,
              data: v
            }
          })
        }]
        return data
      }
    },
    formParmsResult: {
      get () {
        let vm = this
        let data = [{
        //   type: 'select',
        //   label: '项目',
        //   key: 'appraisalItemId',
        //   selectDatas: this.itemList.map(v => {
        //     return {
        //       key: v.id,
        //       label: v.title
        //     }
        //   }),
        //   check: {
        //     required: true
        //   }
        // },
        // {
          type: 'select',
          label: '考核人',
          key: 'appraisalGroupDetailId',
          selectDatas: this.checkList.map(v => {
            return {
              key: v.id,
              label: v.userName,
              data: v
            }
          }),
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '被考核人',
          key: 'beAppraisalGroupDetailId',
          selectDatas: this.showBkhList.map((v) => {
            return {
              key: v.id,
              label: v.userName,
              data: v
            }
          }),
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '分值',
          key: 'value',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          },
          show (formData) {
            let item = vm.itemList.find(v => v.id === formData.appraisalItemId)
            return item && item.ruleMap && item.ruleMap.type === 'input'
          }
        },
        {
          type: 'select',
          label: '分值',
          key: 'value',
          getSelectDatas: (formData) => {
            let item = vm.itemList.find(v => v.id === formData.appraisalItemId)
            let d = item && item.ruleMap && item.ruleMap.optionalList ? item.ruleMap.optionalList : []
            return d.map(v => {
              return {
                key: v.value,
                label: v.label
              }
            })
          },
          check: {
            required: true
          },
          show (formData) {
            let item = vm.itemList.find(v => v.id === formData.appraisalItemId)
            return item && item.ruleMap && item.ruleMap.type === 'select'
          }
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        }]
        return data
      }
    },
    formParmsDetail: {
      get () {
        let data = [{
          type: 'select',
          label: '被考核人',
          key: 'userId',
          selectDatas: this.$store.getters.workerUserList,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '代表单位',
          key: 'represent'
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        },
        {
          type: 'input',
          label: '顺序',
          key: 'orderNum',
          check: {
            rules: {
              type: 'integer'
            }
          }
        }]
        return data
      }
    },
    formParmsStatus () {
      return [
        {
          type: 'select',
          label: '状态',
          key: 'status',
          selectDatas: this.appraisalGroupDetailStatusList,
          check: {
            required: true
          }
        }
      ]
    },
    groupTypeList () {
      return this.$store.getters.appraisalGroupTypeList
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next()
    } else {
      next(from)
    }
  },
  created () {
    this.appraisalId = this.$router.history.current.query.id
    this.$store.dispatch('loadAllWorkerList')
    this.loadCheckList()
    this.loadData()
    this.loadDetailList()
    this.loadResult()
    this.loadItemList()
  },
  methods: {
    async clickDf (parm) {
      if (parm.column.field !== 'beUserName') {
        let appraisalGroupDetailId = Number(parm.column.field)
        this.resultData = {
          appraisalGroupDetailId,
          beAppraisalGroupDetailId: parm.row.id,
          checkedData: parm.row['cd_' + appraisalGroupDetailId] || []
        }
        if (this.appraisalData.type === 'vote') {
          this.openDialogResultVote = true
        } else {
          this.openDialogResult = true
        }
      }
    },
    async loadItemList () {
      this.itemList = await appraisalItemRequest.get({
        appraisalId: this.appraisalId
      })
      this.itemList.forEach(v => v.ruleMap = v.rule ? JSON.parse(v.rule) : {})
    },
    async loadMxList () {
      this.mxList = []
      let parm = {
        appraisalId: this.appraisalId,
        beAppraisalGroupDetailId: this.chooseDataDetail ? this.chooseDataDetail.id : undefined
      }
      let data = await appraisalResultRequest.getDetail(parm)
      let dataMap = {}
      data.forEach((v) => {
        dataMap[v.beAppraisalGroupDetailId] = dataMap[v.beAppraisalGroupDetailId] || {}
        dataMap[v.beAppraisalGroupDetailId][v.appraisalGroupDetailId] = (dataMap[v.beAppraisalGroupDetailId][v.appraisalGroupDetailId] || 0 ) + v.value
        dataMap[v.beAppraisalGroupDetailId]['cd_' + v.appraisalGroupDetailId] = dataMap[v.beAppraisalGroupDetailId]['cd_' + v.appraisalGroupDetailId] ? dataMap[v.beAppraisalGroupDetailId]['cd_' + v.appraisalGroupDetailId].concat(v) : [v]
      })
      this.showBkhList.filter((v2) => !this.chooseDataDetail || (this.chooseDataDetail && this.chooseDataDetail.id === v2.id)).forEach((v) => {
        this.mxList.push(Object.assign({
          beUserName: v.userName,
          id: v.id
        }, dataMap[v.id]))
      })
    },
    clickTitleMenuAll (key) {
      if (['dfmx', 'tj'].includes(key)) {
        this.btnAction(null, {key})
      } else if (key === 'px') {
        this.$refs.bcsrot.openDialogPx = true
      }
    },
    btnAction (item, btnItem) {
      this.chooseDataDetail = item
      if (btnItem.key === 'over') {
        this.nextParm = {
          doing: this.bkhList['ready'].length > 0 ? this.bkhList['ready'][0].id : (this.bkhList['wait'].length > 0 ? this.bkhList['wait'].id : null),
          ready: this.bkhList['wait'].length > 0 ? this.bkhList['wait'][0].id : null
        }
        if (this.bkhList['wait'].length === 0 && this.bkhList['ready'].length === 0) {
          this.formSubmitNext({})
        } else {
          this.openDialogNext = true
          let vm = this
          this.$nextTick(() => {
            vm.$refs.nextForm.verifier()
          })
        }
      // } else if (btnItem.key === 'df') {
      } else if (btnItem.key === 'dfmx') {
        this.loadMxList()
        this.openDialogMx = true
        this.$nextTick(() => {
          this.$tableColumnUpdate(this.$refs.details, 'details')
        })
      } else if (btnItem.key === 'tj') {
        this.tj()
      }
    },
    async loadResult () {
      this.resultList = await appraisalResultRequest.get({
        appraisalId: this.appraisalId
      })
    },
    async tj () {
      this.loading = true
      await appraisalResultRequest.calculate(this.appraisalId)
      await this.loadResult()
      this.loading = false
    },
    tableAction (parm) {
      if (parm.action === 'edit') {
        this.chooseDataDetail = parm.data
        this.openDialogDetail = true
      } else if (parm.action === 'status') {
        this.chooseDataDetail = parm.data
        this.openDialogStatus = true
      } else if (parm.action === 'dfmx') {
        this.chooseDataDetail = parm.data
        this.loadMxList()
        this.openDialogMx = true
        this.$nextTick(() => {
          this.$tableColumnUpdate(this.$refs.details, 'details')
        })
      }
    },
    async formSubmitresult () {
      let parmList = []
      let i = 0
      while (i < this.itemList.length) {
        let item = this.itemList[i]
        let p = null
        try {
          p = this.$refs['appraisalItem_' + item.id][0].getValue()
          Object.assign(p, {
            appraisalId: this.appraisalId,
            appraisalItemId: item.id
          }, this.resultData)
          parmList.push(p)
        } catch (e) {
          console.log(e)
          return
        }
        i += 1
      }
      if (this.loading) {
        return
      }
      this.loading = true
      i = 0
      while (i < parmList.length) {
        await appraisalResultRequest.add(parmList[i])
        this.$refs['appraisalItem_' + parmList[i].appraisalItemId][0].setValue({
          remark: null,
          value: null
        })
        i += 1
      }
      this.openDialogResult = false
      this.$notice.success({
        title: '系统提示',
        desc: '打分完成'
      })
      await this.loadMxList()
      this.loading = false
    },
    async formSubmitNext (data, resolve) {
      this.openDialogNext = false
      this.loading = true
      let allP = []
      if (data.doing) {
        allP.push(appraisalGroupDetailRequest.switchStatus(data.doing, {status: 'doing'}))
      }
      if (data.ready) {
        allP.push(appraisalGroupDetailRequest.switchStatus(data.ready, {status: 'ready'}))
      }
      this.bkhList.doing.forEach((v) => allP.push(appraisalGroupDetailRequest.switchStatus(v.id, {status: 'over'})))
      await Promise.all(allP)
      resolve && resolve()
      this.loading = false
      this.loadDetailList()
    },
    formSubmitStatus (data, resolve) {
      appraisalGroupDetailRequest.switchStatus(this.chooseDataDetail.id, data).then(() => {
        this.$notice.success({
          title: '系统提示',
          desc: '状态修改完成'
        })
        this.loadDetailList()
        this.openDialogStatus = false
        resolve()
      })
    },
    formSubmitDetail (data, resolve) {
      if (this.chooseDataDetail) {
        appraisalGroupDetailRequest.update(this.chooseDataDetail.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组明细修改完成'
          })
          this.loadDetailList()
          this.openDialogDetail = false
          resolve()
        })
      } else {
        data.appraisalGroupId = this.chooseData.id
        appraisalGroupDetailRequest.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组明细新增成功'
          })
          this.loadDetailList()
          this.openDialogDetail = false
          resolve()
        })
      }
    },
    loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
          this.qrUrl = window.location.origin + '/#/wap/' + (this.appraisalData.type === 'scoring' ? 'checking' : this.appraisalData.type) + '?id=' + this.appraisalId
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async loadCheckDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'check'
      }
      return await appraisalGroupDetailRequest.get(parm)
    },
    loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        Object.keys(this.bkhList).forEach(v => this.bkhList[v] = [])
        data.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
        data.forEach((v) => {
          v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
          this.bkhList[v.status].push(v)
        })
      })
    },
    async loadCheckList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'check'
      }
      this.checkList = await appraisalGroupDetailRequest.get(parm)
    }
  }
}
</script>

<style lang="less">
.doing-detail {
  .fm-table-border-row {
    border-bottom: 0;
  }
  .fm-table-border-row .fm-table-head-cells {
    border-top: 0;
  }
  .fm-table-head-item {
    color: rgba(0,0,0,0.85);
  }
  .fm-table-new.fm-table-norm tr {
    height: 35px;
  }
  tr {
    height: 35px !important;
  }
  .fm-table-cell {
    font-size: 12px;
  }
}
</style>

<style lang="less" scoped>
.doing-title {
  margin-left: 48px;
  font-size: 14px;
  color: #333145;
  margin-bottom: 10px;
  line-height: 20px;
  position: relative;
  .d-t-dian {
    position: absolute;
    left: -20px;
    top: 6px;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: #00CCC4;
  }
}
.d-x {
  position: absolute;
  left: -17px;
  width: 1px;
  top: -14px;
  bottom: -30px;
  background: rgba(70,70,70, 0.25);
}
.norm-brn {
  color: #707070;
  font-size: 14px;
}
.base-info {
  height: 24px;
  padding: 18px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .base-info-l {
    display: flex;
    align-items: center;
    color: #333145;
    img {
      width: 24px;
      margin-right: 8px;
    }
  }
  .base-info-r {
    display: flex;
    align-items: center;
    color: #707070;
    font-size: 14px;
  }
}
.top-c {
  position: relative;
  background: #fff;
  margin-bottom: 16px;
  padding: 16px 32px 0;
  height: 160px;
  max-height: 160px;
  .info-1 {
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    line-height: 20px;
  }
  .info-2 {
    font-size: 20px;
    color: #333145;
    line-height: 1;
    margin: 17px 0;
  }
  .qr-img {
    position: absolute;
    bottom: 16px;
    right: 75px;
    span {
      line-height: 1;
    }
  }
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.doing-note {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #999;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  border: 0.5px solid rgba(0,0,0,0.25);
  margin: 0 45px 27px 48px;
  border-radius: 8px;
  color: rgba(0,0,0,0.65);
  line-height: 28px;
  font-size: 14px;
  padding: 7px 9px;
  .bkhr-btns {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin-right: 16px;
  overflow-y: auto;
}
.c-item {
  background: #fff;
  border-radius: 8px;
}
.b-l {
  width: 440px;
  margin: 0 16px;
  overflow-y: auto;
}
.doing-detail {
  height: 100%;
  width: 100%;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c {
  flex: 1;
  height: calc(100% - 176px - 16px);
  display: flex;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>